<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学员统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">课程详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div style="margin:0 14px 20px">
          <span>姓名：{{ userName }}</span>
          <span style="margin:0 40px;">身份证号：{{ idcard }}</span>
          <span style="margin:0 20px;">电话：{{ mobile }}</span>
          <span style="margin:0 20px;">班级名称：{{ projectName }}</span>
        </div>
        <div
          class="operationControl flexdc operationStudent"
          style="align-items: flex-start"
        >
          <div class="searchbox">
            <div title="学时状态" class="searchboxItem ci-full">
              <span class="itemLabel">学时状态:</span>
              <el-select
                v-model="completeState"
                placeholder="请选择"
                clearable
                size="small"
                class="smallselect"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in complete"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div title="通过考试" class="searchboxItem ci-full">
              <span class="itemLabel">通过考试:</span>
              <el-select
                v-model="paperIspass"
                placeholder="请选择"
                clearable
                size="small"
                class="smallselect"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in passExamList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="是否结业" class="searchboxItem ci-full">
              <span class="itemLabel">是否结业:</span>
              <el-select
                v-model="graduationState"
                placeholder="请选择"
                clearable
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in graduationStateList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                width="200"
              />
              <el-table-column
                label="要求总学时"
                align="right"
                show-overflow-tooltip
                prop="requireLessonNum"
              />
              <el-table-column
                label="已完成学时"
                align="right"
                show-overflow-tooltip
                prop="studyLessonNum"
              />
              <el-table-column
                label="是否评价"
                align="center"
                show-overflow-tooltip
                prop="commentState"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.commentState == false ? "否" : "是"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="是否考试通过"
                align="center"
                show-overflow-tooltip
                width="100px"
                prop="paperIsComplete"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paperIsComplete == "1"
                      ? "是"
                      : scope.row.paperIsComplete == "0"
                      ? "否"
                      : "无配置"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="考试次数"
                align="right"
                show-overflow-tooltip
                width="80px"
                prop="paperNum"
              ></el-table-column>
              <el-table-column
                label="学时学习状态"
                align="center"
                width="100px"
                prop="completeState"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.completeState != '40'">{{
                    $setDictionary("LEARNINGSTATE", scope.row.completeState)
                  }}</span>
                  <el-tooltip
                    v-else
                    class="item"
                    effect="dark"
                    :content="scope.row.notCompleteReasons || 0"
                    placement="top"
                  >
                    <span
                      style="
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                      "
                      >{{
                        $setDictionary("LEARNINGSTATE", scope.row.completeState)
                      }}</span
                    >
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                label="是否结业"
                align="center"
                show-overflow-tooltip
                width="100px"
              >
                <template slot-scope="scope">{{
                  scope.row.graduationState ? "是" : "否"
                }}</template>
              </el-table-column>
              <el-table-column
                label="累计在线学习时长"
                align="right"
                show-overflow-tooltip
                prop="totalTime"
                width="150"
              >
              <template slot-scope="scope">
                  {{getTime(scope.row.totalTime)}}
              </template>
              </el-table-column>
              <el-table-column
                label="学习进度(%)"
                align="right"
                show-overflow-tooltip
                prop="percentage"
                width="150"
              />
              <el-table-column
                label="最后一次学习时间"
                align="left"
                show-overflow-tooltip
                prop="lastTime"
                width="150"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { Message } from "element-ui";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/studentStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      projectName: "",
      idcard: "",
      mobile: "",
      userName: "",
      completeState: "",
      complete: [],
      graduationState: "",
      graduationStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      passExamList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "10",
          label: "是",
        },
        {
          value: "20",
          label: "否",
        },
        {
          value: "30",
          label: "无配置",
        },
      ],
      paperIspass:'',
      userId: "",
    };
  },
  created() {
    this.getTableHeight();
    this.getcompleteList();
  },

  computed: {},
  watch: {},
  methods: {
    getSearchParams() {
      let params = {
        projectId: this.$route.query.projectId,
        userId: this.$route.query.userId || "",
        completeState:this.completeState,
        graduationState:this.graduationState,
        paperIsComplete:this.paperIspass
      };
      return params;
    },
    getcompleteList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in studylist) {
          if(key != '40') {
              list.push({
          value: key,
          label: studylist[key],
        });
          }
        
      }
      this.complete = list;
    },

    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      let par = this.getSearchParams();
      params = {
        ...params,
        ...par,
      };
      this.doFetch({
        url: "/biz/report/user/page-RBCourse-list",
        params,
        pageNum,
      });
      this.getListInfo({
        url: "/biz/report/user/userInfoRB",
        params: par,
      });
    },
    getListInfo(params) {
      this.$post(params.url, params.params).then((res) => {
        if (res.status == "0") {
          const data = res.data || {};
          this.userName = data.userName;
          this.mobile = data.mobile;
          this.idcard = data.idcard;
          this.projectName = data.projectName;
        }
      });
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /* 所属企业 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    getTime(val) {
      if (!val) {
        return "";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return h + "时" + m + "分" + s + "秒" || "";
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.operationStudent {
  .itemLabel {
    min-width: 5rem;
  }
  .ci-full {
    .el-input--suffix .el-input__inner {
      padding-right: 3px;
    }
    .el-input {
      input {
        width: 100% !important;
      }
    }
    .smallselect {
      input {
        width: 100% !important;
      }
    }
  }
}
.model {
  .conTip {
    text-align: justify;
    margin-bottom: 20px;
  }
  .itemLabel {
    margin: 10px 0;
  }
}
</style>
